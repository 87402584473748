<template>
  <header
    :data-cy="cyLayoutHeaderSelector"
    class="w-full flex h-[6rem] justify-between z-99 transition-all pointer-events-none"
  >
    <div class="pointer-events-auto">
      <img
        src="/logo-y.png"
        class="absolute top-0 left-0 h-[6rem] md:h-[8rem] transition-all cursor-pointer"
        :data-cy="cyLayoutHeaderImgSelector"
        @click="router.push({name: 'dashboard'})"
      >
    </div>

    <div class="pointer-events-auto my-auto">
      <slot />
      <BaseButton
        v-if="router.currentRoute.value.fullPath.includes('/anmeldung')
          || router.currentRoute.value.fullPath.includes('/registrierung')
          || router.currentRoute.value.fullPath.includes('/reset-password')"
        class="mr-6"
        @click="switchLanguage()"
      >
        {{ getAbbreviation() }}
      </BaseButton>
    </div>
  </header>
</template>

<script setup lang="ts">
import {cyLayoutHeaderImgSelector, cyLayoutHeaderSelector} from './interface'
import {setLanguage} from '~/api/axios'
import {i18n, loadLanguageAsync} from '~/modules/i18n'
const router = useRouter()

function switchLanguage() {
  if (i18n.global.locale.value === 'de') {
    setLanguage('en')
    loadLanguageAsync('en')
  }
  else {
    loadLanguageAsync('de')
    setLanguage('de')
  }
  return true
}

function getAbbreviation() {
  if (i18n.global.locale.value === 'de')
    return 'EN'
  else return 'DE'
}
</script>
