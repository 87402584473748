import _ from 'lodash'

const isoDateFormat
  = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/
const simpleDateFormat = /(\d{4}-[01]\d-[0-3]\d)/

function isIsoDateString(value: string | unknown): boolean {
  return !!(value && typeof value === 'string' && isoDateFormat.test(value))
}

function isSimpleDate(value: string | unknown): boolean {
  return !!(value && typeof value === 'string' && simpleDateFormat.test(value))
}

export function handleResponseDates(body: unknown) {
  if (_.isObject(body) && body !== null && body !== undefined) {
    Object.entries(body).forEach(([key, value]) => {
      if (_.isObject(value))
        handleResponseDates(value)
      else if ((isIsoDateString(value) || isSimpleDate(value)))
        (body as Record<string, unknown>)[key] = new Date(value)
    })
  }
}
