import * as Sentry from '@sentry/vue'
import {BrowserTracing} from '@sentry/tracing'
import {Replay} from '@sentry/replay'
import {type UserModule} from '~/types'

export const install: UserModule = ({app}) => {
  if (import.meta.env.VITE_APP_SENTRY_DNS) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_APP_SENTRY_DNS,
      integrations: [
        new BrowserTracing({
          // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: [import.meta.env.VITE_APP_URL, /^\//],
        }),
        new Replay(),
      ],
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      environment: import.meta.env.MODE,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: +import.meta.env.VITE_APP_SENTRY_TRACE_SAMPLE_RATE || 0,
      release: import.meta.env.VITE_APP_VERSION,
      dist: import.meta.env.VITE_APP_BUILD_ID,
    })
  }
}
