import AuthModule from '~/api/modules/auth'
import CustomersModule from '~/api/modules/customers'
import DietsModule from '~/api/modules/diets'
import InstitutionsModule from '~/api/modules/institutions'
import OrdersModule from '~/api/modules/orders'
import UsersModule from '~/api/modules/users'
import PaymentsModule from '~/api/modules/payments'
import MenuLinesModule from '~/api/modules/menuLines'
import SupplyTypesModule from '~/api/modules/supplyTypes'
import CustomerNotificationsModule from '~/api/modules/customerNotifications'
import AdditivesModule from '~/api/modules/additives'
import HolidaysModule from '~/api/modules/holidays'
import TransactionsModule from '~/api/modules/transactions'
import InvoicesModule from '~/api/modules/invoices'
import CodeModule from '~/api/modules/codes'
import RequestsModule from '~/api/modules/requests'
import GroupModule from '~/api/modules/groups'

const api = {
  auth: AuthModule,
  customers: CustomersModule,
  diets: DietsModule,
  institutions: InstitutionsModule,
  orders: OrdersModule,
  users: UsersModule,
  payments: PaymentsModule,
  menuLines: MenuLinesModule,
  supplyTypes: SupplyTypesModule,
  customerNotifications: CustomerNotificationsModule,
  additives: AdditivesModule,
  holidays: HolidaysModule,
  transactions: TransactionsModule,
  invoices: InvoicesModule,
  codes: CodeModule,
  requests: RequestsModule,
  groups: GroupModule,
}
export default api
