import axiosInstance from '../axios'
import type {Additive} from '~/types/additive.type'

class AdditivesModule {
  getAdditives() {
    return axiosInstance.get<Additive[]>('/additives/')
  }
}

const module = new AdditivesModule()
export default module
