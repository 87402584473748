import {format} from 'date-fns'
import axiosInstance from '../axios'
import {ApiModule} from '../ApiModule'
import type {SupplyType} from '~/types/menuLine.type'

class SupplyTypeModule extends ApiModule {
  getSupplyTypes() {
    return axiosInstance.get<SupplyType[]>('/supply-types/')
  }

  getSupplyTypesByInstitution(institutionId: number, startDate: Date, endDate: Date) {
    this.cancelPreviousRequest(institutionId.toString())
    return axiosInstance.get<SupplyType[]>('/supply-types', {params: {institution: institutionId, startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd')}, signal: this.getAbortController(institutionId.toString())?.signal})
  }
}

const module = new SupplyTypeModule()
export default module
