import {format} from 'date-fns'
import axiosInstance from '../axios'
import type {BillingData} from '~/types/transaction.type'

class TransactionsModule {
  async getTransactions(diningParticipantId: number, startDate: Date) {
    return axiosInstance.get<BillingData>(
        `/dining-participant/${diningParticipantId}/transactions`, {params: {startDate: format(startDate, 'yyyy-MM-dd')}},
    )
  }

  async recharge(diningParticipantId: number, amount: number) {
    return axiosInstance.post(`/dining-participant/${diningParticipantId}/account-events`, {diningParticipantId, amount: amount.toFixed(2)})
  }
}

const module = new TransactionsModule()
export default module
