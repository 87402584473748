import type {ExtractPropTypes} from 'vue'

export const menuFullscreenProps = {
  show: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    default: undefined,
  },
}

export type MenuFullscreenProps = ExtractPropTypes<typeof menuFullscreenProps>
export const cyMenuFullscreenSelector = 'menuFullscreen'
export const cyMenuFullscreenImgSelector = 'menuFullscreenImg'
export const cyMenuFullscreenIconSelector = 'menuFullscreenIcon'
export const cyMenuFullscreenTeleportIconSelector = 'menuFullscreenTeleportIcon'
