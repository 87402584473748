import axiosInstance from '~/api/axios'
import type {User} from '~/types/user.type'

// TODO maybe use useAxios instead
class UsersModule {
  async getUser() {
    return axiosInstance.get<User>('/me')
  }

  async updateUser(id: number, user: Partial<User>) {
    return axiosInstance.put<User>(`/user/${id}/`, user)
  }

  async getBICbyIBAN(ibanObj: object) {
    return axiosInstance.post('/bic', ibanObj)
  }
}

const module = new UsersModule()
export default module
