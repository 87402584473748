import axiosInstance from '~/api/axios'
import type {Code} from '~/types/code.type'

// TODO maybe use useAxios instead
class CodesModule {
  fetchCode(code: string) {
    return axiosInstance.get<Code>('/code/', {params: {code}})
  }
}

const module = new CodesModule()
export default module
