<script setup lang="ts">
import Default from '~/layouts/default.vue'
import Offline from '~/components/offline.vue'
// https://github.com/vueuse/head
// you can use this to manipulate the document head in any components,
// they will be rendered correctly in the html results with vite-ssg
// useHead({
//   title: 'Vitesse',
//   meta: [
//     {name: 'description', content: 'Opinionated Vite Starter Template'},
//     {
//       name: 'theme-color',
//       content: computed(() => isDark.value ? '#00aba9' : '#ffffff'),
//     },
//   ],
//   link: [
//     {
//       rel: 'icon',
//       type: 'image/svg+xml',
//       href: computed(() => preferredDark.value ? '/favicon-dark.svg' : '/favicon.svg'),
//     },
//   ],
// })

const authStore = useAuthStore()

const customerNotificationsStore = useCustomerNotificationStore()
onMounted(async () => {
  if (authStore.user?.customer)
    await customerNotificationsStore.getCustomerNotifications(authStore.user.customer.id)
})

const online = useOnline()
</script>

<template>
  <div class="contents">
    <NotificationBar />
    <RouterView v-if="online" />
    <Default v-else>
      <Offline />
    </Default>
  </div>
</template>
