import {format} from 'date-fns'
import {ApiModule} from '../ApiModule'
import axiosInstance from '~/api/axios'
import type {BulkOrder, DiningParticipantOrder, OrderPause, OrderPauseResponse} from '~/types/orders.type'

class OrdersModule extends ApiModule {
  getDingingParticipantOrders(diningParticipantId: number, startDate: Date, endDate: Date) {
    this.cancelPreviousRequest(diningParticipantId.toString())
    return axiosInstance.get<DiningParticipantOrder[]>(`/dining-participant/${diningParticipantId}/orders/`, {params: {startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd')}, signal: this.getAbortController(diningParticipantId.toString())?.signal})
  }

  async placeOrder(diningParticipantId: number, menuId: number) {
    return axiosInstance.post<DiningParticipantOrder>(`/dining-participant/${diningParticipantId}/orders/`, {menuId})
  }

  async placeOrderWithObligatoryCombination(diningParticipantId: number, menus: Array<number>) {
    return axiosInstance.post<Array<DiningParticipantOrder>>(`/dining-participant/${diningParticipantId}/obligatory-combinations`, {menus})
  }

  cancelOrder(diningParticipantId: number, orderId: number) {
    return axiosInstance.delete(`/dining-participant/${diningParticipantId}/orders/${orderId}`)
  }

  async updateOrder(diningParticipantId: number, menuId: number, orderId: number) {
    return axiosInstance.put<DiningParticipantOrder>(`/dining-participant/${diningParticipantId}/orders/${orderId}`, {menuId})
  }

  pauseOrders(diningParticipantId: number, orderPause: Partial<OrderPause>) {
    if (orderPause.supplyTypeId === -1)
      orderPause.supplyTypeId = undefined

    return axiosInstance.post(`/dining-participant/${diningParticipantId}/order-pauses`, orderPause)
  }

  updateOrderPause(diningParticipantId: number, orderPause: Partial<OrderPause>) {
    let supplyTypeId = orderPause.supplyTypeId
    if (orderPause.supplyTypeId === -1)
      supplyTypeId = undefined

    return axiosInstance.put(`/dining-participant/${diningParticipantId}/order-pauses/${orderPause.id}`, {...orderPause, supplyTypeId})
  }

  fetchOrderPauses(customerId: number) {
    return axiosInstance.get<{diningParticipants: OrderPauseResponse[]}>(`/customer/${customerId}/order-pauses`)
  }

  deleteOrderPause(diningParticipantId: number, orderPauseId: number) {
    return axiosInstance.delete(`/dining-participant/${diningParticipantId}/order-pauses/${orderPauseId}`)
  }

  placeBulkOrder(institutionId: number, payload: Omit<BulkOrder, 'pricePerUnit' | 'institutionId' | 'id'>) {
    return axiosInstance.post<BulkOrder>(`/institution/${institutionId}/bulk-orders`, payload)
  }
}

const module = new OrdersModule()
export default module
