import {acceptHMRUpdate, defineStore} from 'pinia'
import type {Notification} from '~/types/notification.type'

export const useNotificationStore = defineStore('notification', () => {
  const notifications: Ref<Array<Notification>> = ref([])
  function removeNotification(notificationId: number) {
    notifications.value = notifications.value.filter(n => n.id !== notificationId)
  }

  function addNotification(notification: Notification) {
    notification.id = notifications.value.length
    if (notification.autoclose) {
      notification.timer = setTimeout(() => {
        clearTimeout(notification.timer)
        removeNotification(notification.id)
      }, notification.duration)
    }
    notifications.value.push(notification)
  }

  return {
    addNotification,
    removeNotification,
    notifications,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot))
