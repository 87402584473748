import axiosInstance from '~/api/axios'

export interface LoginParams {
  email: string
  password: string
}

export interface RegisterParams {
  email: string
  password: string
  passwordConfirmation: string
}

// TODO maybe use useAxios instead
class AuthModule {
  async getCookie() {
    return axiosInstance.get<unknown>('/sanctum/csrf-cookie')
  }

  login(params: LoginParams) {
    return axiosInstance.post<unknown>('/login', params)
  }

  logout() {
    return axiosInstance.post<unknown>('/logout')
  }

  register(params: RegisterParams) {
    return axiosInstance.post<unknown>('/register', params)
  }

  sendVerificationMail() {
    return axiosInstance.post<unknown>('/email/verification-notification')
  }

  forgotPassword(email: string) {
    return axiosInstance.post<unknown>('/forgot-password', {email})
  }

  resetPassword(params: RegisterParams, token: string) {
    return axiosInstance.post('/reset-password', {...params, token})
  }

  updatePassword(currentPassword: string, password: string, passwordConfirmation: string) {
    return axiosInstance.put('/user/password', {currentPassword, password, passwordConfirmation})
  }
}

const module = new AuthModule()
export default module
