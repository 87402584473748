import axiosInstance from '../axios'
import {ApiModule} from '../ApiModule'
import type {Account} from '~/types/diner.type'
import type {PaymentMethod, PaymentType} from '~/types/payment.type'

// TODO maybe use useAxios instead
class PaymentsModule extends ApiModule {
  getPaymentMethods() {
    const signal = 'paymentMethods'
    this.cancelPreviousRequest(signal)
    return axiosInstance.get<PaymentMethod[]>('/payment-methods', {signal: this.getAbortController(signal)?.signal})
  }

  getPaymentTypes() {
    const signal = 'paymentTypes'
    this.cancelPreviousRequest(signal)
    return axiosInstance.get<PaymentType[]>('/payment-types', {signal: this.getAbortController(signal)?.signal})
  }

  async updateAccount(diningParticipantId: number, account: Partial<Account>) {
    return axiosInstance.put(`/dining-participant/${diningParticipantId}/account`, {...account, defaultCollect: account.defaultCollect?.toFixed(2), minAmount: account.minAmount?.toFixed(2)})
  }
}

const module = new PaymentsModule()
export default module
