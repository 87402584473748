import {ViteSSG} from 'vite-ssg'
import {setupLayouts} from 'virtual:generated-layouts'
// import Previewer from 'virtual:vue-component-preview'

import App from './App.vue'
import type {UserModule} from './types'
import generatedRoutes from '~pages'

import '@unocss/reset/tailwind.css'
import './styles/main.css'
import 'uno.css'

const routes = setupLayouts(generatedRoutes)

// https://github.com/antfu/vite-ssg
export const createApp = ViteSSG(
  App,
  {
    routes,
    base: import.meta.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition)
        return savedPosition
      else
        return {top: 0, left: 0}
    },
  },
  async (ctx) => {
    // install all modules under `modules/`
    await Promise.all(Object.values(import.meta.glob<{install: UserModule}>('./modules/*.ts', {eager: true}))
      .map(i => i.install?.(ctx)))
    // ctx.app.use(Previewer)
  },
)
