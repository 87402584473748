<template>
  <div class="fixed top-0 left-0 z-100 w-full pointer-events-none">
    <div
      class="flex flex-col justify-center w-full items-center mt-3 gap-3 transition-all"
      :data-cy="cyNotificationsBarSelector"
    >
      <TransitionGroup name="list">
        <Notification
          v-for="n in notificationStore.notifications"
          :key="n.id"
          class="w-full md:w-2xl"
          :content="n.message"
          :title="n.title"
          :type="n.type"
          :closable="n.closable"
          :styletype="n.style"
          @close="notificationStore.removeNotification(n.id)"
        >
          {{ n.message }}
        </Notification>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
import {cyNotificationsBarSelector} from './interface'

const notificationStore = useNotificationStore()

</script>

<style lang="scss">
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.list-leave-active {
  position: absolute;
}
</style>
