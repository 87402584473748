import axiosInstance from '~/api/axios'
import type {DiningParticipantRequest} from '~/types/diningParticipantRequest.type'

class RequestsModule {
  createRequest(diningParticipantId: number, request: Partial<DiningParticipantRequest>) {
    return axiosInstance.post<DiningParticipantRequest>(`/dining-participant/${diningParticipantId}/requests`, request)
  }

  fetchRequests(diningParticipantId: number) {
    return axiosInstance.get<DiningParticipantRequest[]>(`/dining-participant/${diningParticipantId}/requests`)
  }

  deleteRequest(diningParticipantId: number, requestId: number) {
    return axiosInstance.delete(`/dining-participant/${diningParticipantId}/requests/${requestId}`)
  }

  downloadFile(diningParticipantId: number, fileId: number) {
    return axiosInstance.get(`/dining-participant/${diningParticipantId}/files/${fileId}/download`, {responseType: 'arraybuffer'})
  }
}

const module = new RequestsModule()
export default module
