import {format} from 'date-fns'
import {ApiModule} from '../ApiModule'
import axiosInstance from '~/api/axios'
import type {HolidayResponse} from '~/types/holiday.type'

class HolidaysModule extends ApiModule {
  getHolidays(customer: number, startDate: Date, endDate: Date) {
    const signal = `${customer}-holidays`
    this.cancelPreviousRequest(signal)
    return axiosInstance.get<HolidayResponse[]>('/holidays/', {params: {startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd'), customer}, signal: this.getAbortController(signal)?.signal})
  }
}

const module = new HolidaysModule()
export default module
