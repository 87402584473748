<template>
  <footer
    :data-cy="cyLayoutFooterSelector"
    class="flex w-full px-5 py-5 justify-between text-xl"
  >
    <div class="flex items-center text-blue-500 w-full relative">
      <div
        id="flyingBee"
        :class="['w-full', flyingBee ? 'animate-fly md:animate-duration-3s lg:animate-duration-4s xl:animate-duration-5s' : '']"
      >
        <img
          src="/bee.svg"
          :class="['items-center w-5 z-100']"
          alt=""
          @click="handleClick"
        >
      </div>
      <div
        class="italic text-sm -bottom-2 left-3 absolute hidden sm:block font-bold"
        @click="flyingBee = false"
      >
        {{ t('footer.slogan') }}
      </div>
    </div>
    <div class="flex justify-end gap-4">
      <a
        href="https://rws-gruppe.de/impressum/"
        target="_blank"
        :data-cy="cyLayoutFooterImprint"
      >
        {{ t('footer.imprint') }}
      </a>
      <a
        href="/AGB_Final_Stand_05.12.2024.pdf"
        target="_blank"
        :data-cy="cyLayoutFooterTos"
      >
        {{ t('footer.tos') }}
      </a>
      <a
        href="https://rws-gruppe.de/datenschutz/"
        target="_blank"
        :data-cy="cyLayoutFooterPrivacy"
        class="whitespace-nowrap"
      >
        {{ t('footer.privacy') }}
      </a>
    </div>
  </footer>
</template>

<script setup lang="ts">
import {cyLayoutFooterImprint, cyLayoutFooterPrivacy, cyLayoutFooterSelector, cyLayoutFooterTos} from './interface'
const {t} = useI18n()
const flyingBee = ref(false)
let clickCount = 0

function handleClick() {
  if (flyingBee) flyingBee.value = false
  clickCount++

  setTimeout(() => {
    clickCount = 0
  }, 1000)

  if (clickCount === 3) {
    flyingBee.value = true
    clickCount = 0
  }
}
</script>
