export class ApiModule {
  abortControllers = new Map<string, AbortController>()

  getAbortController(id: string) {
    if (this.abortControllers.has(id)) { return this.abortControllers.get(id) }
    else {
      this.abortControllers.set(id, new AbortController())
      return this.abortControllers.get(id)
    }
  }

  cancelPreviousRequest(id: string) {
    this.getAbortController(id)?.abort()
    this.abortControllers.set(id, new AbortController())
  }
}

