import axiosInstance from '~/api/axios'
import type {Diet} from '~/types/diet.type'

class DietsModule {
  getDiets() {
    return axiosInstance.get<Diet[]>('/diet/')
  }
}

const module = new DietsModule()
export default module
