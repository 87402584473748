import type {ExtractPropTypes} from 'vue'
import type {PropType} from 'vue-demi'

export const NOTIFICATION_TYPE = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
  SYSTEM: 'system',
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NOTIFICATION_TYPE = typeof NOTIFICATION_TYPE[keyof typeof NOTIFICATION_TYPE]

export const notificationProps = {
  title: {
    type: String,
    default: '',
  },
  content: {
    type: String,
    default: '',
  },
  closable: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<NOTIFICATION_TYPE>,
    default: NOTIFICATION_TYPE.DEFAULT,
  },
  styleType: {
    type: String,
    default: 'default',
  },
}

export type NotificationProps = ExtractPropTypes<typeof notificationProps>
export const cyNotificationSelector = 'notification'
