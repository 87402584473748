import {format} from 'date-fns'
import axiosInstance from '../axios'
import type {Invoice} from '~/types/invoice.type'

class InvoiceModule {
  fetchInvoices(customerId: number, startDate: Date, endDate: Date) {
    return axiosInstance.get<Invoice[]>(`/customer/${customerId}/invoices`,
      {params: {startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd')}})
  }

  downloadInvoice(customerId: number, invoiceId: number) {
    return axiosInstance.get(`/customer/${customerId}/invoices/${invoiceId}/download`, {responseType: 'arraybuffer'})
  }

  send(customerId: number, invoiceId: number) {
    return axiosInstance.get(`/customer/${customerId}/invoices/${invoiceId}/send`)
  }
}

const module = new InvoiceModule()
export default module
